import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Icon,
  Heading,
  Flex,
  Text,
  Badge,
  Divider,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { MdHome } from 'react-icons/md'
import { FaFistRaised } from 'react-icons/fa'
import { Helmet } from 'react-helmet'
import ogImage from '../images/og_image.jpeg'

const ImpressumPage = () => {
  return (
    <>
      <Helmet
        title="Sovie - Impressum"
        meta={[
          {
            name: `description`,
            content: `Impressum und Offenlegung.`,
          },
          {
            property: `og:title`,
            content: `Sovie - Impressum`,
          },
          {
            property: `og:url`,
            content: `https://www.sovie.or.at/impressum/`,
          },
          {
            property: `og:locale`,
            content: `de_DE`,
          },
          {
            property: `og:description`,
            content: `Impressum und Offenlegung.`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `https://sovie.or.at${ogImage}`,
          },
          {
            property: `og:site_name`,
            content: `SoVie Österreich`,
          },
          {
            property: `article:publisher`,
            content: `https://www.facebook.com/SoVie-%C3%96sterreich-114495926645116/`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: `sovie.or.at`,
          },
          {
            name: `twitter:image`,
            content: `https://sovie.or.at${ogImage}`,
          },
          {
            name: `twitter:title`,
            content: `Sovie - Impressum`,
          },
          {
            name: `twitter:description`,
            content: `Impressum und Offenlegung.`,
          },
        ]}
      ></Helmet>
      <Box as="section" pt="5rem">
        <Box
          maxWidth="75rem"
          ml="auto"
          mr="auto"
          pl="1.5rem"
          pr="1.5rem"
        >
          <Breadcrumb
            spacing="8px"
            fontSize="sm"
            separator={<ChevronRightIcon color="red.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink as={GatsbyLink} to="/">
                <Icon as={MdHome} />
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>Impressum</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Box>
      <Box as="section" pt="2rem" pb="7.5rem">
        <Flex
          maxW="75rem"
          ml="auto"
          mr="auto"
          pl="1.5rem"
          pr="1.5rem"
        >
          <Box mt="1rem" flex="1 1 0">
            <Heading as="h1" fontSize="4xl" ml="auto" mr="auto">
              <Box colorScheme="red">Impressum</Box>
            </Heading>
            <Badge borderRadius="full" mr="2" colorScheme="red">
              Wir
            </Badge>
            <Badge borderRadius="full" mr="2" colorScheme="red">
              SOVIE
            </Badge>
            <Badge borderRadius="full" colorScheme="red">
              <Icon as={FaFistRaised} />
            </Badge>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Medieninhaber, Herausgeber, Hersteller und Eigentümer
              </Heading>
              <Text fontSize="md">
                Sozialdemokratische Vielfalt - Verein zur Förderung
                der Diversität in der Sozialdemokratie, kurz{' '}
                <b>SOVIE</b>
              </Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Adresse
              </Heading>
              <Text fontSize="md">
                Elisabethstraße 42, 5020 Salzburg
              </Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Kontakt
              </Heading>
              <Text fontSize="md">office@sovie.or.at</Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                VZR
              </Heading>
              <Text fontSize="md">1861612632</Text>
            </Box>
            <Divider mt="4" />
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Obmann
              </Heading>
              <Text fontSize="md">Mag. Mustafa Durmus</Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Obmann Stellvertreterin
              </Heading>
              <Text fontSize="md">Samira Mujkanovic</Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Schriftführer
              </Heading>
              <Text fontSize="md">Dr. Tarik Mete</Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Frauensprecherin
              </Heading>
              <Text fontSize="md">Patricia Katsulis</Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Kassier
              </Heading>
              <Text fontSize="md">Sinan Tepe</Text>
            </Box>
            {/*
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Grundlegende Richtung, Vereinszweck
              </Heading>
              <Text fontSize="md">
                Lorem ipsum dolor sit amet, consetetur sadipscing
                elitr, sed diam nonumy
              </Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Datenschutzbeauftragte
              </Heading>
              <Text fontSize="md">
                Lorem ipsum dolor sit amet, consetetur sadipscing
                elitr, sed diam nonumy
              </Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Spendenkonto
              </Heading>
              <Text fontSize="md">
                Lorem ipsum dolor sit amet, consetetur sadipscing
                elitr, sed diam nonumy
              </Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Haftung für Links
              </Heading>
              <Text fontSize="md">
                Lorem ipsum dolor sit amet, consetetur sadipscing
                elitr, sed diam nonumy
              </Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Haftung für Inhalte
              </Heading>
              <Text fontSize="md">
                Lorem ipsum dolor sit amet, consetetur sadipscing
                elitr, sed diam nonumy
              </Text>
            </Box>
            <Box mt="1.5rem">
              <Heading as="h2" fontSize="md">
                Programmierung
              </Heading>
              <Text fontSize="md">
                Lorem ipsum dolor sit amet, consetetur sadipscing
                elitr, sed diam nonumy
              </Text>
            </Box> */}
          </Box>

          <Box flex="1 1 0"></Box>
        </Flex>
      </Box>
    </>
  )
}

export default ImpressumPage
